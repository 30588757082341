import nyanCat from './nyan-cat.gif';
import './App.css';

console.log('BUILD TIME ENV:', process.env?.REACT_APP_DASHBOARD_TYPE);

const getDashboardType = () => {
  if (process.env?.REACT_APP_DASHBOARD_TYPE === 'AGENCY') return 'Agency';
  if (process.env?.REACT_APP_DASHBOARD_TYPE === 'CREATOR') return 'Creator';

  return 'Brand';
};

function App() {
  const dashboardType = getDashboardType();

  return (
    <div className="App">
      <header className="App-header">
        <img src={nyanCat} className="App-logo" alt="logo" />
        <p>Vamp Amplify Test - {dashboardType} Dashboard</p>
      </header>
    </div>
  );
}

export default App;
